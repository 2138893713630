.edit-salary-slip {
    color: var(--primaryColor);
    margin-top: 4px
}

.admin-search-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    flex-shrink: 1;
}

.salary-slip-heading {
    height: 42px;
    display: flex;
    justify-content: space-between;
}

.salary-slip-sub-container {
    height: calc(100% - 42px);
}

.contain {
    background-color: antiquewhite;
}

.admin-salary-slip-search-container {
    width: 260px;
}

.custom-icon {
    color: 'blue'
}

.ag-center-aligned-header {
    text-align: center;
}



.salaryInfo-details-container {
    height: 25px;
    font-weight: 600;
}

.grid-details-container {
    height: calc(100% - 25px) !important
}

@media screen and (max-width:1240px) {
    .salaryInfo-details-container {
        height: 25px !important;
    }

    .grid-details-container {
        height: calc(100% - 25px) !important;
    }
}

@media screen and (min-width:300px) and (max-width:376px) {
    .salary-slip-heading {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }

    .admin-salary-slip-search-container {
        width: 240px;
    }

    .salary-slip-heading h2 {
        font-size: 16px;
    }

    .salary-slip-sub-container {
        height: calc(100% - 60px);
    }
}

@media screen and (min-width:377px) and (max-width:600px) {
    .salary-slip-heading {
        height: 34px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .salary-slip-heading h2 {
        font-size: 16px;
    }

    .admin-salary-slip-search-container {
        width: 60%;
    }

    .salary-slip-sub-container {
        height: calc(100% - 34px);
    }
}

@media screen and (min-width:600px) and (max-width:750px) {
    .salary-slip-heading {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }

    .admin-salary-slip-search-container {
        width: 260px;
    }

    .salary-slip-sub-container {
        height: calc(100% - 70px);
    }
}

@media screen and (min-width:751px) and (max-width:812px) {
    .admin-salary-slip-search-container {
        width: 50%;
    }
}

@media screen and (min-width:813px) and (max-width:1024px) {
    .admin-salary-slip-search-container {
        width: 50%;
    }
}