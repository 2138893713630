.holiday-sub-container {
  overflow-y: auto;
  height: calc(100% - 35px);
  /* border: 1px solid red */
}

.holiday-list-sub-container {
  margin: 0;
  display: flex;
  width: 100%;
  padding-left: 25%;
  padding-Right: 25%;
  justify-content: center;
}


/* Boilerplate stuff */

.holiday-list-container {
  height: 100%;
  display: flex;
  justify-content: center;
}


/*table*/

/* TABLE STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.table-wrapper {
  height: 100%;
  width: 100%;
}


/* .table-wrapper::-webkit-scrollbar {
    height: 8px;
  }
  
  .table-wrapper::-webkit-scrollbar-thumb {
    background: var(--darkblue);
    border-radius: 40px;
  }
  
  .table-wrapper::-webkit-scrollbar-track {
    background: var(--white);
      border-radius: 40px;
  }
  
  .table-wrapper table {
    border-collapse: collapse;
  }
  
  .table-wrapper table th,
  .table-wrapper table td {
    padding: 10px;
    min-width: 75px;
  }
  
  .table-wrapper table th {
    color: var(--white);
    background: var(--darkblue);
  }
  
  .table-wrapper table tbody tr:nth-of-type(even) > * {
    background: var(--lightblue);
  } */

/* .table-wrapper table td:first-child {
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-gap: 10px;
    text-align: left;
  } */

.table-credits {
  font-size: 12px;
  margin-top: 10px;
}

/* FOOTER STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: fixed;
  right: 0;
  bottom: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
  z-index: 1;
  background: var(--white);
}

.page-footer a {
  display: flex;
  margin-left: 4px;
}