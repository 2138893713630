.view-button {
    background-color: var(--tertiarycolor);
    color: var(--primaryColor);
    border: 1px solid var(--primaryColor) !important;
}

.view-button:hover {
    color: var(--tertiarycolor);
    background-color: var(--primaryColor) !important;
    border: 1px solid var(--primaryColor);
}