.flex-item {
    border-radius: 10px;
    height: 220px;
    min-width: 100%;
    padding-bottom: 5px;
    color: var(--tertiarycolor);
    text-align: center;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}



.flex-item-heading {
    background-color: var(--primaryColor);
    height: 25px;
    padding: 1%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: var(--primaryColor) 0px -2px 6px 0px inset;
    display: flex;
    justify-content: space-between;
}

.sub-container-wrapper {
    height: 100%;
    overflow-x: hidden;
    padding-right: 10px;
}

.flex-item-heading p {
    font-size: 100%;
}

.dashboard-details-container {
    height: calc(100% - 25px);
    overflow-y: hidden;
}

.dashboard-details-container:hover {
    overflow-y: auto;
}

.sub-container-wrapper::-webkit-scrollbar,
.dashboard-details-container::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.sub-container-wrapper::-webkit-scrollbar-track,
.dashboard-details-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor1);
    border-radius: 10px;
}

/* Handle */
.sub-container-wrapper::-webkit-scrollbar-thumb,
.dashboard-details-container::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 10px;
}

/* Handle on hover */
.sub-container-wrapper::-webkit-scrollbar-thumb:hover,
.dashboard-details-container::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor);
}

.dashboard-details-item-container {
    font-size: 1rem;
    color: black;
    text-align: start;
    padding: 2px 10px;
    margin-top: 1px;
    display: flex;
    gap: 5px;
    justify-content: space-between;
}

.status-circle-yellow {
    color: var(--secondaryColor)
}

.status-circle-red {
    color: var(--dangerColor)
}


.status-outline-redo {
    border: 1px solid var(--secondaryColor);
    border-radius: 10px;
    color: var(--secondaryColor)
}

/* .dashboard-content-details-button-container {
    font-size: 50px !important;
    height: 12px !important;
    margin: 0px 5px 0px 0px !important;
} */
.dashboard-content-details-button-container {
    font-size: 50px !important;
    height: 12px !important;
    margin: 0px 5px 0px 0px !important;
}

.work-from-home-icon {
    color: var(--successColor);
    font-size: 20px;
    border: 1px solid var(--successColor);
    border-radius: 10px;
    padding: 2px;
    font-weight: 900;
}

@media (max-width:600px) {
    .flex-item {
        min-width: 90%;
    }
}