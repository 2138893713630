.reset-password-main-container {
    position: relative;
    font-size: 14px;
}

.reset-password-sub-container h4 {
    margin-bottom: 10px;
}


.reset-toggle-password-button1 {
    position: absolute;
    top: 70%;
    right: 50px;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.1;
    /* color: red; */
}

.reset-toggle-password-button {
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.1;
    /* color: red; */
}

.reset-toggle-password-button:hover {
    opacity: 1;
    color: var(--primaryColor);
}