.edit-main-container {
    height: 100%
}

.edit-employee-heading {
    font-weight: 200;
    text-align: center;
    font-size: 120%;
    padding-bottom: 1%;
    color: var(--primaryColor1);
    height: 10% !important;
}

.edit-sub-container {
    height: 100%;
}

.edit-sub-form-container {
    width: 100%;
    height: 100%;
    padding-right: 5px;
    padding-bottom: 5px;
}

.edit-form-container {
    /* box-shadow: 25px 25px 25px 30px rgba(0, 0, 0, 0.1); */
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #d4cdcd;
    height: 100%;
    /* min-width: 90%; */
}

.edit-form-container h3 {
    color: var(--primaryColor);
    font-size: 16px;
    height: 35px;
    padding-bottom: 10px;
    /* background-color: blue; */
}

.edit-field-container {
    overflow-y: scroll;
    height: 88%;
    padding: 5px 5px 2px;
    /* border: 1px solid red; */
}

.edit-field-container input {
    margin-bottom: 10px;
    margin-top: 5px;
}



.edit-field-container::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.edit-field-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor1);
    border-radius: 10px;
}

/* Handle */
.edit-field-container::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 10px;
}

/* Handle on hover */
.form-field-stack-container::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor) !important;
}

.form-field-stack-container::-webkit-scrollbar {
    width: 3px !important;
}

/* Track */
.form-field-stack-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor1) !important;
    border-radius: 10px !important;
}

/* Handle */
.form-field-stack-container::-webkit-scrollbar-thumb {
    background: var(--primaryColor) !important;
    border-radius: 10px !important;
}

/* Handle on hover */
.form-field-stack-container::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor) !important;
}

.adress-checkbox {
    margin-top: -1% !important;
}

.address-type-header {
    margin-top: 2%;
    font-weight: 600;
    font-size: 15px;
}

.label {
    font-size: 16px;
    font-weight: 700;
}



h3.edit-employee-heading span {
    position: relative;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 10px;
    border-bottom: 1px solid var(--primaryColor);
}

h3.edit-employee-heading span:after {
    content: '';
    position: absolute;
    bottom: calc(-100% - 1px);
    margin-left: -10px;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border-top: 1px solid var(--primaryColor);
}

.edit-employee-button-container {
    text-align: center;
    height: 100%;
}

.edit-employee-basicSalary-container {
    display: flex;
    gap: 5%;
    flex-wrap: wrap;
}

.edit-employee-basicSalary-label-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.edit-employee-basicSalary-percentage-container {
    display: flex;
    flex-direction: column;
    flex-basis: 80px;
    justify-content: center;
    align-items: flex-start;
}

.info-icon {
    color: var(--tertiarycolor);
    font-size: 10px !important;
    opacity: 1;
    background-color: var(--primaryColor);
    border-radius: 20px;
    margin-top: 2px;
    margin-left: 2px;
    padding: 0px;
}

.info-icon:hover {
    opacity: 1;
}

.edit-employee-details-container {
    display: flex;

}

.edit-employee-details-container1 {
    display: flex;
    flex-direction: column;

}

.edit-employee-details-sub-container {
    margin-top: -14px;
    padding-right: 10px;
}

.basic-salary-container {
    display: flex;
    align-items: center;
    gap: 4px;
    /* justify-content: center; */
}


.field-container {
    flex-basis: '300px'
}

.field-container1 {
    flex-basis: '350px'
}

.form-field-stack-container {
    height: 89%;
    overflow-y: auto;
    padding-bottom: 25px;
    padding-right: 15px;
    margin-bottom: 10px;
}

.form-button-stack-container {
    height: 10%
}

.edit-field-container label {
    font-size: 80%;
}

@media (max-width:768px) {
    .info-icon {
        font-size: 10px;
    }
}


@media screen and (min-width:300px) and (max-width:600px) {
    .edit-employee-heading {
        font-size: 16px !important;
    }

    .edit-form-container h4 {
        font-size: 14px !important;
    }

    .edit-field-container label {
        font-size: 12px !important;
    }

    .edit-field-container input {
        font-size: 12px !important;
    }

    .edit-field-container p {
        font-size: 13px !important;
    }
}