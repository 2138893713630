.approve-status-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    border: 1px solid var(--successColor);
    padding: 4px;
    border-radius: 10px;
    margin-right: 6px;
    gap:2px
}
.reject-status-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    border: 1px solid var(--dangerColor);
    padding: 4px;
    border-radius: 10px;
    margin-right: 6px;
    gap:2px
}