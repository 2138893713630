.employee-registration-main-container {
    height: 100%
}

.employee-registration-main-container h3 {
    text-align: center;
    height: 5%
}

.employee-registration-sub-container {
    height: 95%
}

.form-container {
    /* box-shadow: 25px 25px 25px 30px rgba(0, 0, 0, 0.1); */
    padding: 5px;
    margin: 5px;
    border-radius: 10px;
    border: 1px solid #d4cdcd;
    height: 100%;
    /* overflow-y: auto; */
}

.sub-from-container {
    height: 100%;
    overflow: auto
}


.form-container h3 {
    color: var(--primaryColor);
    font-size: 16px;
}

.employee-registration-field-container {
    padding: 4%;
}

.sub-from-container::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.sub-from-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor) !important;
    border-radius: 10px;
}

/* Handle */
.sub-from-container::-webkit-scrollbar-thumb {
    background: var(--primaryColor) !important;
    border-radius: 10px;
}

/* Handle on hover */
.sub-from-container::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor) !important;
    background-color: var(--primaryColor) !important;
}

.field-sub-container {
    padding: 3px 3px;
}


.field-sub-container input {
    padding: 5px 5px;
}


.field-container::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.field-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor1);
    border-radius: 10px;
}

/* Handle */
.field-container::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 10px;
}

/* Handle on hover */
.field-container::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor);
}

.adress-checkbox {
    margin-top: -1% !important;
}

.address-type-header {
    margin-top: 2%;
    font-weight: 600;
    font-size: 15px;
}


.label {
    font-size: 16px;
    font-weight: 700;
}

select {
    height: 45px;
    border-radius: 5px;
    border: 1px solid #ccc
}


.employee-registration-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px !important;
}

.employee-registration-container {
    justify-content: center;
    align-items: center;
    height: 336px !important;
}


@media screen and (min-width:300px) and (max-width:600px) {
    .employee-registration-header h3 {
        font-size: 16px !important;
    }

    .employee-registration-field-container label {
        font-size: 14px !important;
    }

    .employee-registration-field-container input {
        font-size: 14px !important;
    }
}