/* .sub-container {
    overflow: hidden;

} */

.lop-attendance-heading {
    height: 62px;
    padding-top: 5px;
}

.lop-attendance-sub-heading {
    height: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.lop-date-range-container {
    display: flex;
    gap: 4px;
    justify-content: space-between;
    height: 100%;
}

.lop-sub-date-range {
    display: flex;
    gap: 2px
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    height: 100%;
}

.lop-attendance-sub-container {
    height: calc(100% - 62px);
    padding-top: 5px;

}

.mui-table-container {
    max-height: 100%;
    width: 100%
}

.table-cell-container {
    display: flex;
}

.table-cell-container .width25 {
    min-width: 100px !important;
}

.date-range-container {
    justify-content: flex-start;
    align-items: center;
    gap: 2px !important;
}

.lop-download-button-container {
    border-radius: 20px !important;
}

.lop-sub-table-container {
    height: 100%;
    width: 100%;
    overflow: auto !important;
}

.css-rorn0c-MuiTableContainer-root {
    overflow: visible !important;
}

.lop-sub-table-container::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
}

/* Track */
.lop-sub-table-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor) !important;
    border-radius: 10px !important;
}

/* Handle */
.lop-sub-table-container::-webkit-scrollbar-thumb {
    background: var(--primaryColor) !important;
    border-radius: 10px !important;
}

/* Handle on hover */
.lop-sub-table-container::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor) !important;
    background-color: var(--primaryColor) !important;
}

.lop-sub-date-range .css-19nxzat-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}

.lop-download-button-main-container {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center
}



.lop-sub-table-container .css-rorn0c-MuiTableContainer-root {
    /* overflow: hidden; */
}



.date-field-container {
    height: 38px;
}

.body-width25 {
    width: 25%;
    font-size: 14px;
}

.heading-container {
    display: flex;
    justify-content: center;
    align-items: center
}

@media screen and (min-width:300px) and (max-width:420px) {

    .lop-download-button-main-container {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .date-field-container {
        height: 30px;
    }

    .lop-sub-date-range label {
        font-size: 14px;
        height: 20px;
    }

    .heading-container {
        justify-content: flex-start;
        height: 25px;
    }

    .lop-attendance-heading {
        height: 150px;
    }

    .lop-attendance-sub-container {
        height: calc(100% - 150px);
    }

    .lop-attendance-heading h2 {
        font-size: 18px;
    }

    .lop-attendance-sub-heading {
        flex-direction: column;
        height: 100% !important;
        justify-content: flex-start;
    }

    .lop-sub-date-range {
        flex-direction: column;
        width: 100% !important;
        gap: 15px;
    }

    .lop-date-range-container {
        height: calc(100% - 25px);
    }
}

@media screen and (min-width:421px) and (max-width:520px) {

    .lop-download-button-main-container {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .heading-container {
        justify-content: flex-start;
        height: 15%;
    }

    .lop-attendance-heading {
        height: 130px;
    }

    .lop-attendance-sub-container {
        height: calc(100% - 130px);
    }

    .lop-attendance-heading h2 {
        font-size: 18px;
    }

    .lop-attendance-sub-heading {
        flex-direction: column;
        height: 100% !important;
    }

    .lop-date-range-container {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100px;
    }

    .lop-sub-date-range {
        width: 100% !important;
        height: 56px;
    }

    .lop-download-button-main-container {
        height: 40px;
        align-items: flex-end;
        width: 100%;
    }
}

@media screen and (min-width:521px) and (width<600px) {
    .lop-download-button-main-container {
        height: 92%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lop-attendance-heading {
        height: 90px;
    }

    .lop-attendance-sub-container {
        height: calc(100% - 90px);
    }

    .lop-attendance-heading h2 {
        font-size: 18px;
    }

    .lop-attendance-sub-heading {
        flex-direction: column;
    }

    .heading-container {
        justify-content: flex-start;
    }

    .lop-sub-date-range {
        width: 100% !important;
    }

    .lop-sub-date-range {
        height: 60px;
    }

}

@media screen and (min-width:600px) and (max-width:720px) {
    .lop-download-button-main-container {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .lop-attendance-heading {
        height: 140px !important
    }

    .lop-attendance-sub-container {
        height: calc(100% - 140px) !important;
    }

    .lop-attendance-sub-heading {
        align-items: flex-start !important;
        flex-direction: column;
        height: 100% !important;
    }

    .lop-sub-date-range {
        justify-content: space-between;
        gap: 4px;
        height: 60px !important;
    }

    .lop-date-range-container {
        flex-direction: column;
    }

    .lop-download-button-main-container {
        height: 34px;
    }
}

@media screen and (min-width:721px) and (max-width:1100px) {

    .heading-container {
        justify-content: flex-start;
        width: 100% !important;
        height: 100% !important;
    }

    .lop-attendance-sub-heading {
        align-items: flex-start !important;
        flex-direction: column;
        height: 100% !important;
    }

    .lop-attendance-heading {
        height: 100px !important;
    }

    .lop-attendance-sub-container {
        height: calc(100% - 100px) !important;
    }

    .lop-date-range-container {
        width: 100% !important;
        height: 100% !important;
        padding-top: 2px;
    }

    .lop-sub-date-range {
        justify-content: space-between;
        gap: 4px;
        height: 60px !important;
    }

    .lop-download-button-main-container {
        height: 56px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}