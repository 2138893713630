.attendance-date-container .css-160unip {
  height: 100% !important;
  overflow: hidden;
}

.attendance-date-container .css-160unip .css-1bn53lx {
  height: calc(100% - 8px) !important;
}

.attendance-date-container {
  width: 100%;
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-top {
  display: flex;
  width: 100%;
}

.custom-header {
  display: flex;
  align-items: center;
  /* Center vertically */
  justify-content: center;
  /* Center horizontally */
}

.heading2 .MuiInputBase-input {
  height: 10px !important
}

.attendance-list-container table td {
  padding: 4px !important;
}

.table-head {
  padding: 3%
}

.table-row-head {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 10px !important;
  width: 100%;
}

.table-row-head1 {
  /* padding: 10px !important */
  width: 13% !important;
}

.width20 {
  width: 20%;
}

.width12 {
  width: 12%;
}

.width25 {
  width: 25%;
}

.width25 {
  width: 25%;
}

.width16 {
  width: 16%;
}

.width10 {
  width: 10%;
}

.width60 {
  width: 60%;
}



.attendance-dates-container {
  /* width: 100%; */
  height: 49px;
}

.download-button-container {
  border-radius: 20px !important;
  margin-top: 10px !important;
}

.heading2 {
  height: 11%;
  padding: 10px 2px 2px 15px;
}

.attendance-sub-container {
  overflow: auto;
  height: 100%;
  padding-top: 0px;
}

.attendance-sub-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.attendance-sub-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle */
.attendance-sub-container::-webkit-scrollbar-thumb {
  background: var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle on hover */
.attendance-sub-container::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.attendance-sub-container .table button:hover {
  background: var(--primaryColor);
  color: #fff;
  transition: 0.5s;
}

.attendance-sub-container .table button {
  color: var(--primaryColor);
  background-color: #fff;
  transition: 0.5s;
}

.date-range-sub-container {
  display: flex;
  gap: 2px
}

.header-container {
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
}

.attendance-body-grid {
  height: calc(100% - 70px) !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
  overflow-y: hidden;
}

.header-sub-container {
  display: flex;
  gap: 3px
}

@media (max-width:1440px) {
  .attendance-dates-container .css-4jnixx-MuiStack-root {
    height: 49px !important;
    overflow: hidden !important;
  }

  .attendance-dates-container .css-4jnixx-MuiStack-root>.MuiTextField-root {
    height: 40px;
  }

  .attendance-date-container .css-160unip {
    height: 100% !important;
    overflow: hidden;
  }

  .attendance-date-container .css-160unip .css-1bn53lx {
    height: calc(100% - 8px) !important;
  }
}



@media screen and (min-width:300px) and (max-width:421px) {
  .header-container h2 {
    font-size: 16px !important;
  }

  .header-container h2 {
    text-align: left;
  }

  .header-container {
    flex-direction: column;
    height: 135px !important;
    justify-content: flex-start !important;
    align-items: flex-start;
    padding-bottom: 20px !important;
  }

  .date-range-sub-container {
    flex-direction: column;
    gap: 14px;
  }

  .css-4jnixx-MuiStack-root>.MuiTextField-root {
    min-width: 170px !important;
  }

  .header-container .button-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .attendance-body-grid {
    height: calc(100% - 135px) !important;
    padding-top: 10px;

  }
}

@media screen and (min-width:420px) and (max-width:600px) {
  .header-container h2 {
    font-size: 16px !important;
  }

  .header-container h2 {
    text-align: left;
  }

  .header-container {
    flex-direction: column;
    height: 125px !important;
    justify-content: flex-start !important;
    align-items: flex-start;
    padding-bottom: 20px !important;
  }

  .header-sub-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }


  .header-container .button-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .attendance-body-grid {
    height: calc(100% - 125px) !important;
    padding-top: 10px;

  }
}

@media screen and (min-width:600px) and (max-width:735px) {

  .header-container h2 {
    text-align: left;
  }

  .header-container {
    flex-direction: column;
    height: 136px !important;
    justify-content: flex-start !important;
    align-items: flex-start;
    padding-bottom: 20px !important;
  }

  .header-sub-container {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }


  .css-4jnixx-MuiStack-root>.MuiTextField-root {
    min-width: 170px !important;
  }

  .header-container .button-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .attendance-body-grid {
    height: calc(100% - 136px) !important;
    padding-top: 10px;

  }
}

@media screen and (min-width:735px) and (max-width:1030px) {

  .header-container h2 {
    text-align: left;
  }

  .header-container {
    flex-direction: column;
    height: 100px !important;
    justify-content: flex-start !important;
    align-items: flex-start;
    padding-bottom: 20px !important;
  }

  .header-sub-container {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }


  .css-4jnixx-MuiStack-root>.MuiTextField-root {
    min-width: 170px !important;
  }

  .header-container .button-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .attendance-body-grid {
    height: calc(100% - 100px) !important;
    padding-top: 10px;

  }
}