.ag-theme-alpine {
  /* --ag-header-foreground-color: var(--tertiarycolor) !important; */
  --ag-header-background-color: var(--primaryColor) !important;
}

.ag-body-vertical-scroll-viewport::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.ag-body-vertical-scroll-viewport::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle */
.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb {
  background: var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle on hover */
.ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle */
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
  background: var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle on hover */
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.delete-employee-button {
  color: var(--dangerColor);
}

.edit-employee-button {
  color: var(--primaryColor) !important;
}



.back-button {
  color: var(--primaryColor);
  font-size: 150% !important;
}

.employee-heading-container {
  height: 10%
}

.employee-sub-heading-container {
  padding: 10px 40px 0px 0px
}

.employee-sub-heading-arrow-item {
  padding-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-sub-heading-add-item {
  padding: 0px 40px 0px 40px;
  height: 35px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.employee-sub-container {
  height: calc(100% - 35px);
  padding-top: 10px !important;
}



@media (max-width:478px) {
  .employee-sub-heading-container {
    /* font-size: 80%; */
    white-space: nowrap;
  }
}

@media screen and (min-width:300px) and (max-width:600px) {
  .employee-heading-sub-container h2 {
    font-size: 18px !important;
  }
}