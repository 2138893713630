.user-lop-heading {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
}

.user-lop-date-range-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    gap: 2px;
}

.user-lop-sub-container {
    height: calc(100% - 40px);
    padding-top: 5px;
}


@media screen and (min-width:300px) and (max-width:376px) {
    .user-lop-heading h2 {
        font-size: 18px;
    }

    .user-lop-date-range-container {
        width: 100%;
    }

    .user-lop-heading {
        height: 60px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .user-lop-sub-container {
        height: calc(100% - 60px);
    }
}

@media screen and (min-width:377px) and (max-width:586px) {
    .user-lop-heading {
        height: 60px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .user-lop-date-range-container {
        width: 100%;
    }

    .user-lop-sub-container {
        height: calc(100% - 60px);
    }

    .user-lop-heading h2 {
        font-size: 18px !important;
    }
}

@media screen and (min-width:587px) and (max-width:600px) {
    .user-lop-heading {
        height: 35px;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
    }

    .user-lop-date-range-container {
        width: 62%;
    }

    .user-lop-sub-container {
        height: calc(100% - 35px);
    }

    .user-lop-heading h2 {
        font-size: 18px !important;
    }
}

@media screen and (min-width:600px) and (max-width:832px) {
    .user-lop-heading {
        height: 68px;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

    .user-lop-date-range-container {
        width: 100%;
    }

    .user-lop-sub-container {
        height: calc(100% - 68px);
    }
}