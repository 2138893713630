/* .dashboard-heading {
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 4px;
} */

/* .sub-container {
    height: calc(100% - 30px);
} */

.time-tracker {
    text-align: center;
    margin: 50px auto;
    width: 300px;
    padding: 0px 0px 5px 0px;
    border: 1px solid #ccc;
    box-shadow: 20px 20px 15px rgb(0 0 0 / 13%);
    border-radius: 5px;
}

.time-tracker h1 {
    font-size: 150%;
    color: var(--tertiarycolor);
    background-color: #007bff;
    border-radius: 5px;
}

@media (max-width: 575px) {
    .time-tracker {
        width: 240px;
        box-shadow: 15px 15px 10px rgb(0 0 0 / 13%);

    }

    .time-tracker h1 {
        font-size: 100%;
    }

    .clock-in,
    .clocked-out button {
        padding: 5px 5px;

    }

}

.status {
    margin: 5px 0;
}

.button-width {
    width: '20px' !important
}

.clocked-in,
.clocked-out,
.breaked-in,
.breaked-out {
    margin-top: 10px !important;
}

.clock-in-out {
    padding-bottom: 10px;
}

.clock-out-button {
    background-color: red;
}

.clocked-in button:disabled {
    background-color: rgb(191, 111, 111);
}

button {
    min-height: 10px;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.break-button {
    margin-top: 10px;
}


/*Code for timer*/
.time-count-container span {
    border-radius: 15px;
    color: var(--primaryColor);
    font-size: 120%;
    padding: 1%;
}

.time-count-container {
    margin: 10px 0px;
}

@media screen and (min-width:300px) and (max-width:426px) {
    .heading h2 {
        font-size: 18px !important;
    }

    .heading p {
        font-size: 12px !important;
    }
}

@media screen and (min-width:600px) and (max-width:1024px) {
    .heading p {
        font-size: 15px !important;
    }
}

@media screen and (min-width:427px) and (max-width:600px) {
    .heading h2 {
        font-size: 18px !important;

    }

    .heading p {
        font-size: 14px !important;
    }
}