.table th,
tbody {
    text-align: center;
}

.table thead {
    z-index: 1;
}

.attendance-list-heading {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
}

.attendance-list-heading p {
    font-size: 15px;
}

.user-attendance-table-container {
    height: calc(100% - 40px);
    padding-top: 5px;
}

.user-attendance-table-container::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.user-attendance-table-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor) !important;
    border-radius: 10px;
}

/* Handle */
.user-attendance-table-container::-webkit-scrollbar-thumb {
    background: var(--primaryColor) !important;
    border-radius: 10px;
}

/* Handle on hover */
.user-attendance-table-container::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor) !important;
    background-color: var(--primaryColor) !important;
}

/* .user-attendance-table-container tbody {
    background-color: blue;
    overflow: scroll;
} */

@media screen and (min-width:300px) and (max-width:480px) {
    .attendance-list-heading h2 {
        font-size: 18px !important;
    }

    .attendance-list-heading {
        height: 58px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .user-lop-date-range-container {
        width: 100%;
    }

    .user-attendance-table-container {
        height: calc(100% - 58px);
    }


}



@media screen and (min-width:481px) and (max-width:600px) {
    .attendance-list-heading h2 {
        font-size: 18px !important;

    }

    .attendance-list-heading {
        height: 32px;
        justify-content: space-between;
        gap: 5px;
        align-items: flex-start;

    }

    .user-lop-date-range-container {
        width: 64% !important;
    }

    .user-attendance-table-container {
        height: calc(100% - 32px);
    }
}

@media screen and (min-width:600px) and (max-width:832px) {
    .attendance-list-heading {
        height: 68px;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
    }

    .user-attendance-table-container {
        height: calc(100% - 68px);
    }
}