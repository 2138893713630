.create-holiday-heading {
    display: flex;
    justify-content: space-between;
    gap: 2%;
    height: 10%
}

.create-holiday-heading h3 {
    padding: 2px;
}

.create-holiday-sub-form-container {
    height: calc(100% - 44px) !important;
    /* border: 1px solid red; */
}

.create-wfh-button-container {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    padding-top: 8px;
}

.create-holiday-close-button {
    font-size: 18px;
    margin-top: 10px;
}

.create-holiday-sub-container {
    height: 90%
}

.create-holiday-form-container {
    height: 100%
}

.create-holiday-field-container {
    height: 85%;
}

.create-holiday-field-sub-container {
    width: 100%;
    display: flex;
    gap: 5px;
    flex-direction: column
}

.create-holiday-button-container {
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}