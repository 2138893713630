* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  --primaryColor: #2770de;
  --primaryColor1: #161546;
  --primaryColorRGB: 4, 30, 65;
  --secondaryColor: #ff9900;
  --secondaryColorRGB: 255, 153, 0;
  --tertiarycolor: #f8f9fa;
  --quaternaryColor: #000;
  --dangerColor: #f00c0ccc;
  --box-shadow-selected: 0 3px 15px rgb(0 0 0 / 13%);
  --whiteBackground: #fff;
  --successColor: rgb(57, 175, 57);
  --grayColor: #c4c4c4;
}

html,
body {
  max-width: 100vw;
  overflow: hidden;
}

#close-btn {
  background-color: var(--dangerColor);
  color: var(--tertiarycolor);
}

#primary-btn {
  background-color: var(--primaryColor);
  color: var(--tertiarycolor);
}

#submit-btn {
  background-color: var(--primaryColor);
  color: var(--tertiarycolor);
}

main {
  width: 100%;
  background-color: #fff !important;
}

.stack {
  height: 100% !important;
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
}

.layout-main-container {
  display: flex;
  height: 100vh;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

.form-field {
  flex: 2;
}

.output {
  margin-left: 3rem;
  flex: 1;
}

input {
  height: 35px;
  width: 100%;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  border: 1px solid rgb(220, 220, 220);
}

.services {
  display: flex;
  justify-content: space-between;
}

.first-division {
  display: flex;
  flex-direction: column;
  margin: 0 5px 0.5rem 0;
  width: 100%;
}

button {
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
  padding: 5px 10px;
  margin-top: 1px;
}

.first-division button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
}

.second-division button {
  color: red;
  border: 1px solid red;
}

/*table*/
.table {
  border-collapse: collapse;
  margin: 0px 0;
  font-size: 15px;
  min-width: 100%;
  border-radius: 5px 5px 0 0;
}

table thead tr {
  color: #fff;
  background: var(--primaryColor);
  text-align: left;
  font-weight: bold;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px;
}

.table tbody tr {
  border-bottom: 1px solid #ddd;
}

.table tbody tr:nth-of-type(even) {
  background: #f3f3f3;
}

.table tbody tr.active {
  font-weight: bold;
  color: var(--primaryColor);
}

.table button {
  padding: 6px 20px;
  border-radius: 10px;
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--primaryColor);
}

.table button:hover {
  background: var(--primaryColor);
  color: #fff;
  transition: 0.5rem;
}

.heading {
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 4px;
}

.sub-container {
  height: calc(100% - 30px);
}

.heading1 {
  height: 11%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 4px;
  /* border: 1px solid red */
}

/* .sub-container {
  overflow-y: auto;
  height: 89%;
  padding-top: 0px;
} */

/* .sub-container::-webkit-scrollbar {
  width: 5px;
}

Track
.sub-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primaryColor) !important;
  border-radius: 10px;
}

Handle
.sub-container::-webkit-scrollbar-thumb {
  background: var(--primaryColor) !important;
  border-radius: 10px;
}

Handle on hover
.sub-container::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.sub-container .table button {
  width: 100%;
  padding: 7px;
  cursor: pointer;
  border-radius: 10px;
  background: transparent;
  color: var(--primaryColor);
}

.sub-container .table button:hover {
  background: var(--primaryColor);
  color: #fff;
  transition: 0.5s;
} */

/* Attendance List section */

.attendance {
  margin-top: 20px;
  text-transform: capitalize;
  /* height: 300px !important;
    overflow-y: scroll; */
}

.main-container {
  width: 100%;
  padding: 0px 40px 40px 40px;
  padding-top: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.table thead {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
}

.leave-balance-show-container {
  color: var(--tertiarycolor);
  border-radius: 8px;
  margin-right: 65% !important;
  margin-top: 0px;
  padding: 4px;
  width: 32%;
  background-color: var(--successColor);
}

.leave-balance-show-container1 {
  color: var(--tertiarycolor);
  border-radius: 8px;
  margin-right: 65% !important;
  margin-top: 0px;
  padding: 4px;
  width: 30%;
  background-color: var(--dangerColor);
}

.css-8vnh0h-MuiPaper-root-MuiAlert-root {
  background-color: var(--primaryColor) !important;
  margin-top: 10%;
}

.employee-container {
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  /* padding-top: 10px; */
}

.heading4 {
  display: flex;
  justify-content: space-between;
  height: 35px !important;
  align-items: center;
}

.employee-back-button-heading-container {
  display: flex;
  gap: 17px;
  height: 100%;
  align-items: center;
  padding-right: 40px;
  padding-top: 15px;
}

/*Holiday-css*/
.holiday-nodata-container {
  display: flex;
  margin-top: 85px;
}

.holiday-nodata-alert-icon {
  color: var(--dangerColor);
}

.holiday-nodata-container>div {
  margin-top: 7px;
}

.error-field {
  font-size: 10px;
  color: var(--dangerColor);
  font-weight: 600;
  height: 10px;
}

.modal-class {
  position: fixed;
  z-Index: 0;
}

.search-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  flex-shrink: 1;
}

.avatar {
  background-color: var(--primaryColor) !important;
  width: 28px !important;
  height: 27px !important;
  margin-top: 2.5px !important;
}

.circular-add-button {
  color: var(--primaryColor);
  font-size: 200% !important
}

.date-range-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: 5px
}

.css-k008qs header {
  background-color: #FFFFFF !important;
  color: var(--primaryColor);
}

.download-button-container {
  border-radius: 20px !important;
  margin-top: 15px !important;
}

.button-style {
  padding: 9px;
  border-radius: 3px
}

.button-class button {
  padding: 2px 10px !important;
  margin-bottom: 5px !important;
}

.button-class-top button {
  padding: 3px 18px !important;
}


@media screen and (min-width:600px) and (max-width:1440px) {
  main {
    width: calc(100% - 250px);
    background-color: #fff !important;
  }
}