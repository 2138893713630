/* * * * * General CSS * * * * */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  /* color: #666666; */
  background: #eaeff4;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container {
  position: relative;
  width: 100%;
  max-width: 958px;
  max-height: auto;
  display: flex;
  background: #fff fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  height: 500px;
}

.credit {
  position: relative;
  margin: 25px auto 0 auto;
  width: 100%;
  text-align: center;
  color: var(--primaryColor);
  font-size: 16px;
  font-weight: 400;
}

.credit a {
  color: var(--primaryColor);
  font-size: 16px;
  font-weight: 600;
}

/* * * * * Login Form CSS * * * * */
/*  */

.login-form h2 {
  margin: 0 0 15px 0;
  font-size: 30px;
  font-weight: 700;
}

.login-text h2 {
  margin: 0 0 15px 0;
  font-weight: 700;
}

h2 img {
  width: 120px;
}

p {
  /* margin: 0 0 20px 0; */
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}

.btn {
  display: inline-block;
  padding: 7px 20px;
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none;
  border-radius: 5px;
  color: #ffffff;
  outline: none;
  border: 1px solid #ffffff;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.btn:hover {
  color: var(--primaryColor) !important;
  background: #ffffff;
}

.btn-1 {
  display: inline-block;
  padding: 7px 20px;
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none;
  border-radius: 5px;
  color: #ffffff;
  outline: none;
  border: 1px solid #ffffff;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.btn-1:hover {
  color: var(--primaryColor);
  background: #ffffff;
}

.link_btn {
  text-decoration: none;
  color: white;
}

.link_btn:hover {
  color: var(--primaryColor) !important;
}

.col-left,
.col-right {
  width: 55%;
  padding: 45px 35px;
  display: flex;
}

.col-left {
  width: 45%;
  background: var(--primaryColor);
  border-radius: 0px 15px 15px 0px;
  /* -webkit-clip-path: polygon(
    98% 17%,
    100% 34%,
    98% 51%,
    100% 68%,
    98% 84%,
    100% 100%,
    0 100%,
    0 0,
    100% 0
  );
  clip-path: polygon(
    98% 17%,
    100% 34%,
    98% 51%,
    100% 68%,
    98% 84%,
    100% 100%,
    0 100%,
    0 0,
    100% 0
  ); */
}

@media (max-width: 575px) {
  .container {
    flex-direction: column;
    box-shadow: none;
    padding-right: 24px;
  }

  .col-left,
  .col-right {
    width: 100%;
    margin: 0;
    padding: 35px 50px !important;
    -webkit-clip-path: none;
    clip-path: none;
  }
}

.login-text {
  position: relative;
  width: 100%;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.login-form {
  position: relative;
  width: 100%;
  color: var(--primaryColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form p:last-child {
  margin: 0;
}

.login-form p a {
  /* color: #09443c; */
  color: var(--primaryColor);
  font-size: 14px;
  text-decoration: none;
}

.login-form p:last-child a:last-child {
  float: right;
}

.login-form label {
  display: block;
  width: 100%;
  margin-bottom: 2px;
  letter-spacing: 0.5px;
}

.login-form p:last-child label {
  width: 60%;
  float: left;
}

.login-form label span {
  color: #ff574e;
  padding-left: 2px;
}

.login-form input {
  /* display: block; */
  width: 90%;
  height: 40px;
  padding: 0 10px;
  font-size: 16px;
  letter-spacing: 1px;
  outline: none;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.login-form input:focus {
  border-color: #ff574e;
}

.login-form input.btn {
  color: #ffffff;
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  outline: none;
  cursor: pointer;
}

.login-form input.btn:hover {
  color: var(--primaryColor);
  background: #ffffff;
  border: 1px solid var(--primaryColor);
}

.password-input {
  margin-bottom: 20px;
}

.input-container {
  position: relative;
}

input {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.toggle-password-button {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  cursor: pointer;
  opacity: 0.1;
  /* color: var(----tertiarycolor); */
}

.toggle-password-button:hover {
  opacity: 1;
  color: var(--primaryColor);
}


@media screen and (min-width:300px) and (max-width:426px) {
  .wrapper {
    height: 100%;
  }

  .container {
    height: 100%;
  }
}