.admin-team-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
}

.admin-team-sub-container {
    height: calc(100% - 30px);
    padding-top: 5px;
}

.add-team-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-details-button-container Button {
    border: 1px solid var(--primaryColor);
    padding: 3px;
    margin-bottom: 3px;
}

.team-details-button-container>Button:hover {
    background-color: var(--primaryColor);
    color: aliceblue;
}

@media screen and (min-width:300px) and (max-width:600px) {
    .admin-team-heading h2 {
        font-size: 18px;
    }

}