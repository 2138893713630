.wfh-approve-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    padding: 0px;
    border-radius: 10px;
    margin-right: 6px;
    gap: 2px;
}

.wfh-reject-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    padding: 4px;
    border-radius: 10px;
    margin-right: 6px;
    gap: 2px
}

.wfh-pending-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    padding: 4px;
    border-radius: 10px;
    margin-right: 6px;
    gap: 2px;
}

.wfh-sub-container {
    overflow-y: auto;
    height: 100%;
}




@media screen and (min-width:300px) and (max-width:426px) {
    .heading4 h2 {
        font-size: 15px;
    }



}

@media screen and (min-width:600px) and (max-width:1024px) {
    .heading4 h2 {
        font-size: 20px;
    }


}

@media screen and (min-width:427px) and (max-width:600px) {
    .heading4 h2 {
        font-size: 18px;
    }
}