.edit-holiday-sub-container {
    padding-top: 20px;
}

.edit-holiday-main-container h3 {
    margin-left: 4px;
}

.close-button {
    color: var(--dangerColor);
}