.leave-review-heading {
  height: 35px;
}

.leave-review-container {
  height: calc(100% - 35px);
  overflow: auto;
}


.leave-review-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.leave-review-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle */
.leave-review-container::-webkit-scrollbar-thumb {
  background: var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle on hover */
.leave-review-container::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.holiday-list-sub-container {
  margin: 0;
  display: flex;
  width: 100%;
  padding-left: 25%;
  padding-right: 25%;
  justify-content: center;
}

/* Boilerplate stuff */

.holiday-list-container {
  height: 100%;
  display: flex;
  justify-content: center;
}

/*table*/

/* TABLE STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.table-wrapper {
  height: 100%;
  width: 100%;
}

.table-credits {
  font-size: 12px;
  margin-top: 10px;
}

.holiday-list-container {
  height: 100%;
  display: flex;
  justify-content: center;
}

/*table*/

/* TABLE STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.table-wrapper {
  height: 100%;
  width: 100%;
}

.table-credits {
  font-size: 12px;
  margin-top: 10px;
}

/* FOOTER STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: fixed;
  right: 0;
  bottom: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
  z-index: 1;
  background: var(--white);
}

.page-footer a {
  display: flex;
  margin-left: 4px;
}

.page-footer {
  position: fixed;
  right: 0;
  bottom: 50px;
  display: flex;
  align-items: center;
  padding: 5px;
  z-index: 1;
  background: var(--white);
}

.page-footer a {
  display: flex;
  margin-left: 4px;
}

.student-profile .card .card-header .profile_img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 10px auto;
  border: 10px solid #ccc;
  border-radius: 50%;
}

.student-profile .card h3 {
  font-size: 20px;
  font-weight: 700;
}

.student-profile .card p {
  font-size: 16px;
  color: #000;
}

.student-profile .table th,
.student-profile .table td {
  font-size: 14px;
  padding: 5px 10px;
  color: #000;
}

.main-con::-webkit-scrollbar {
  width: 5px;
}

/* Track */

.main-con::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle */

.main-con::-webkit-scrollbar-thumb {
  background: var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle on hover */

.main-con::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.leave-review-sub-container {
  display: flex;
  width: 100%
}

.leave-review-field-container {
  width: 35%;
  border: 1px solid #eee;
  padding: 3%;
  text-align: center;
  font-weight: 600
}

.leave-review-field-container1 {
  width: 5%;
  padding: 3%;
}

.leave-review-field-container2 {
  border: 1px solid #eee;
  width: 60%;
  padding: 3%
}

.leave-review-field-container3 {
  border: 1px solid #eee;
  width: 25%;
  padding: 3%;
  font-weight: 600;
}

.leave-review-field-container4 {
  border: 1px solid #eee;
  width: 70%;
  padding: 3%;
}

.leave-review-field-container5 {
  border: 1px solid #eee;
  width: 70%;
  display: flex;
}

.leave-review-field-container-file {
  padding: 3%;
  display: flex;
  justify-content: space-between;
}

.leave-review-field-container-no-file {
  width: 100%;
  padding: 3%;
}

.leave-review-data-container {
  border: '1px solid #eee';
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.leave-review-left-data-container {
  display: flex;
  flex-direction: column;
  flex-basis: 40%
}

.leave-review-right-data-container {
  display: flex;
  flex-direction: column;
  flex-basis: 60%
}

.leave-review-download-button-container {
  border-radius: 20px !important;
  /* margin-top: 15px !important; */
  padding: 0px !important;
  background-color: var(--primaryColor) !important;
  /* color: var(--white) !important; */
}

.leave-review-field-container-file button:hover {
  color: #eee !important;
}

@media screen and (min-width:300px) and (max-width:600px) {
  .leave-review-heading h2 {
    font-size: 18px !important;
  }
}