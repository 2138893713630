.search-container {
    width: 90px !important;

}

.user-salaryInfo-details-container {
    height: 25px;
    font-weight: 600;
}

.user-salary-grid-details-container {
    height: calc(100% - 25px);
    width: 100%
}

.salary-sub-container {
    overflow-y: auto;
    height: calc(100% - 35px);
    padding-top: 0px;
    /* border: 1px solid red */
}