.admin-leave-sub-container thead tbody tr {
  width: 100% !important;
}




.main {
  position: relative;
  width: 100%;
  height: 100%;
}

.admin-leave-heading {
  height: 33px;
}

.admin-leave-sub-container {
  height: calc(100% - 33px);
  overflow: auto;
}

.admin-leave-sub-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.admin-leave-sub-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle */
.admin-leave-sub-container::-webkit-scrollbar-thumb {
  background: var(--primaryColor) !important;
  border-radius: 10px;
}

/* Handle on hover */
.admin-leave-sub-container::-webkit-scrollbar-thumb:hover {
  background: var(--primaryColor) !important;
  background-color: var(--primaryColor) !important;
}

.main-top {
  display: flex;
  width: 100%;
}


.heading1 {
  margin-bottom: 0px;
  height: 11%;
}

.search-input {
  padding: 10px 10px !important;
  height: 0px !important;
}

.leave-search-container {
  /* flex-basis: 50%; */
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  gap: 2px
}

.leave-sub-search-container {
  border: 1px solid;
  padding: 3px 10px 3px 16px;
  border-radius: 50px;
  height: 30px;
  flex-basis: 90%
}

.sub-container {
  overflow-x: auto;
}




.table thead th {
  padding: 5px;
}


@media screen and (min-width:300px) and (max-width:600px) {
  .admin-leave-sub-heading h2 {
    font-size: 18px !important;
  }
}