.team-create-container {
    height: 400px;
    font-size: 14px;
}

.team-create-sub-container {
    margin-left: 20px;
    margin-top: 10px;
    height: 100%;
}

.team-create-form-container {
    height: 87%;
    overflow-y: auto;
    padding-right: 20px;
    margin-right: 2px;
}

.team-create-form-container::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.team-create-form-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--primaryColor1);
    border-radius: 10px;
}

/* Handle */
.team-create-form-container::-webkit-scrollbar-thumb {
    background: var(--primaryColor);
    border-radius: 10px;
}

/* Handle on hover */
.team-create-form-container::-webkit-scrollbar-thumb:hover {
    background: var(--primaryColor);
}

.team-form-container-label {
    font-size: 16px;
    font-weight: 700;
}

.team-create-close-button {
    margin-right: 4px;
}

.select-input-field {
    height: 40px;
}

.select-menu-field {
    height: 20px;
}

@media screen and (max-width: 380px) {
    .team-create-sub-container.heading1 h2 {
        font-size: 100%;
    }



}

.MuiAutocomplete-tag {
    background-color: rgb(236, 227, 227) !important;
    border-radius: 20px !important;
}

.MuiAutocomplete-input {
    padding: 0 !important;
}